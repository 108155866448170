const GoogleGmailAddon = (function () {

    return {
        gmailAddonInit: gmailAddonInit,
    }

   function gmailAddonInit() {
        const agreeBtn = document.getElementById("addonAgreeBtn");
        const disAgreeBtn = document.getElementById("addonDisAgreeBtn");
        let step3TextH = document.getElementById("step3TextH");
        let step3textP = document.getElementById("step3TextP");

        agreeBtn.onclick = () => {
            setDisplayNextStep(true)
        };

        disAgreeBtn.onclick = () => {
            step3TextH.textContent = i18next.t(main.thirdParty.refuse);
            step3textP.textContent = i18next.t(main.thirdParty.closeWindow);
            setDisplayNextStep(false)
        };

    }
    function setDisplayNextStep(agree) {
        document.getElementById("googleGmailAddonStep2").style.display = "none";
        document.getElementById("googleGmailAddonStep3").style.display = "block";
        setTimeout(function() {
            const locHref  = new URL(location.href);
            if(agree){
                if(locHref.searchParams.get('state') === 'outlook' || locHref.searchParams.get('state') === 'teams'){
                    Ajax.executeApi("ACT_OUTLOOK_ADDIN_JWT",
                    {
                            USER_ID: locHref.searchParams.get('code').replaceAll("'",""),
                            ACCESS_TOKEN: locHref.searchParams.get('access_token'),
                            REFRESH_TOKEN: locHref.searchParams.get('refresh_token')
                        },
                        function (dat) {
                            location.href=locHref.searchParams.get('redirect_uri') +"?user_id=" + locHref.searchParams.get('code') + '&access_token=' + dat.ACCESS_TOKEN + "&refresh_token=" + dat.REFRESH_TOKEN + "&vaildation=" + dat.VAILDATION + "&status=" + dat.STATUS;
                    });
                } else {
                    location.href=locHref.searchParams.get('redirect_uri') + '?state=' + locHref.searchParams.get('state') + "&code=" + locHref.searchParams.get('code');
                }
            } else {
                top.window.close();
            }
        }, 1000);
    }
})()
