var ThirdPartyUtil = (function () {

    return {
        load: load,
    }

    /**
     * @param {string} mode - main, messenger
     */
    function load({googleMap}) {
        if ((ServerChecker.isInnerNetwork && Often.checkInnerIp()) && !EnterCommonConfig.isExternalLogin() ) {
            return;
        }

        if (Often.isFunc(Func.ENTER.GOOGLE_MAP) && googleMap) GoogleMap.load();
        if (Often.isFunc(Func.CLOUD.DROPBOX_UPLOAD)) DropboxDrive.load();
        if (Often.isFunc(Func.CLOUD.GOOGLEDRIVE_UPLOAD)) GoogleDrive.load();
        // if (true) TeamsOneDrive.load();
    }

})()
