var GiphyUtil = function () {

    const API_KEY = "cPsTzNMNsRomrz0jA1xEmCEbaSg3Ea3m";
    const URL = "https://api.giphy.com/v1/";
    const GIF_URL = "https://i.giphy.com/media/{#id}/{#option}.gif";
    const PREVIEW_URL = "https://i.giphy.com/media/{#id}/giphy-preview.gif";
    const GIF_TYPE = "gifs";
    const STICKER_TYPE = "stickers";
    const LIMIT = 25;
    const keywords = [
        "안녕","좋아","싫어","오케이","대박",
        "화이팅","슬퍼","어휴","ㅋㅋ","우와",
        "고마워","헐","배고파","박수","화나"
    ];

    let giphyUI;
    let giphyType;
    let offset = 0;
    let pagingFlag = true;
    let $giphyWrap, $giphyList, $giphySearch, $giphyContent, $giphyOption;

    return {
        init,
    }

    function init(type) {
        giphyUI = type;
        $giphyWrap = $(".giphy-wrap").visible()[0];
        $giphyContent = $giphyWrap.querySelector(".giphy-content");
        $giphyList = $giphyWrap.querySelector(".giphy-content-list");
        $giphySearch = $giphyWrap.querySelector(".js-giphy-search");
        $giphyOption = $giphyWrap.querySelector(".js-giphy-file-option");
        $giphySearch.focus();
        
        setEvent();
        reset();
    }

    function setEvent() {
        $giphyWrap.querySelector(".btn-giphy-type-gif").addEventListener("click", clickGifType); // gif 버튼
        $giphyWrap.querySelector(".btn-giphy-type-sticker").addEventListener("click", clickStickerType); // 스티커 버튼
        if ($giphyWrap.querySelector(".giphy-close"))
            $giphyWrap.querySelector(".giphy-close").addEventListener("click", closeGiphy);
        if ($giphyWrap.querySelector(".giphy-out-layer"))
            $giphyWrap.querySelector(".giphy-out-layer").addEventListener("click", closeGiphy);
        $giphySearch.addEventListener("keyup", searchGiphy);
        $giphyContent.addEventListener("scroll", scrollPaging);
    }

    function reset() {
        offset = 0;
        $giphySearch.value = "";
        changeGiphyType(null);
        giphyType = getGiphyType();
        giphyContentList();
    }

    // gif 버튼 함수
    function clickGifType(e) {
        const $target = e.target;
        changeGiphyType($target);
        giphyType = getGiphyType();

        if ($giphySearch.value !== "") {
            offset = 0;
            $giphyContent.scrollTop = 0;
            searchGiphy();
        }
    }

    // 스티커 버튼 함수
    function clickStickerType(e) {
        const $target = e.target;
        changeGiphyType($target);
        giphyType = getGiphyType();

        if ($giphySearch.value !== "") {
            offset = 0;
            $giphyContent.scrollTop = 0;
            searchGiphy();
        }
    }

    function getGiphyType() {
        return $giphyWrap.querySelector(".btn-giphy-type.active").dataset.type;
    }

    function changeGiphyType(target) {
        const btns = $giphyWrap.getElementsByClassName("btn-giphy-type");
        for (const btn of btns) {
            btn.classList.remove("active");
        }
        if(target) target.classList.add("active");
        else $giphyWrap.querySelector(".btn-giphy-type:first-child").classList.add("active");
    }

    function resetList() {
        $giphyList.innerHTML = "";
    }

    function searchGiphy(e) {
        if(e) {
            if (e.keyCode === 13) {
                const value = e.target.value;
                if (value.trim() !== "") {
                    offset = 0;
                    $giphyContent.scrollTop = 0;
                    searchItem(value);
                }
                return;
            }
            if([8, 46].indexOf(e.keyCode) >= 0 && e.target.value === "") {
                giphyContentList();
            }
        } else {
            searchItem($giphySearch.value);
        }
    }

    async function searchItem(searchText) {
        const type = giphyType === "gif" ? GIF_TYPE : STICKER_TYPE;
        const response = await fetch(setUrl(searchText, type));
        response.json().then((dataList) => {
            if(offset === 0) {
                resetList();
            }

            for (const data of dataList.data) {
                craeteImageItem(data);
            }
            pagingFlag = true;
        });
    }

    function setUrl(search, type) {
        return `${URL}${type}/search?api_key=${API_KEY}&q=${search}&limit=${LIMIT}&offset=${offset}&rating=g&lang=ko`;
    }

    function getGifUrl(id) {
        return GIF_URL.replace("{#id}", id).replace("{#option}", $giphyOption.value);
    }

    function giphyContentList() {
        resetList();
        for (const keyword of keywords) {
            craeteKeywordItem(keyword);
        }
        offset = 0;
        $giphyContent.scrollTop = 0;
    }

    function craeteKeywordItem(value) {
        const item = $giphyList.appendChild(document.createElement("li"));
        item.classList.add("giphy-keyword-item");
        item.innerText = value;
        item.addEventListener("click", (e) => {
            offset = 0;
            $giphyContent.scrollTop = 0;
            $giphySearch.value = e.target.innerText.trim();
            searchItem($giphySearch.value);
            $giphySearch.focus();
        });
    }

    function craeteImageItem(data) {
        const item = $giphyList.appendChild(document.createElement("li"));
        const previewInfo = data.images.preview_gif;
        item.dataset.id = data.id;
        // css
        item.classList.add("giphy-content-item");
        item.style.width = `${(100 / Number(previewInfo.height)) * Number(previewInfo.width)}px`;
        item.style.backgroundImage = `url('${PREVIEW_URL.replace("{#id}", data.id)}')`;

        item.addEventListener("click", async (e) => {
            const imgId = e.target.dataset.id;
            const url = getGifUrl(imgId);
            const file = await convertURLtoFile(url);

            await Upload.putFilesAndSend("any", [file], (file, queueNum) => {
                if(giphyUI === "post") {
                    PostAppend.appendFileOrImg(PostPopup.replacePostArea($("#postPopup").visible()), file);
                } else if (giphyUI === "chat") {
                    MessengerSend.sendFile(file, queueNum);
                } else if (giphyUI === "comment") {
                    ItemRemark.drawUploadFile(file, $($giphyWrap.closest(".js-remark-layer")));
                } else {
                    // pass
                }
            });

            closeGiphy();
        });
    }

    async function convertURLtoFile(url) {
        const response = await fetch(url);
        const data = await response.blob();
        const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
        const orgFilename = url.split("/").pop(); // url 구조에 맞게 수정할 것
        const filename = orgFilename.replace(".", `_${_USER_ID}_${Math.floor(+ new Date() / 1000)}.`);
        const metadata = { type: `image/${ext}` };
        return new File([data], filename, metadata);
    }
    
    // 스크롤 페이징
    function scrollPaging(e) {
        const $target = e.target;
        let scrollBottomPos = $target.clientHeight + $target.scrollTop;
        let scrollHeight = $target.scrollHeight;
        let allowPagingHeight = scrollHeight * 0.9;

        if(scrollBottomPos >= allowPagingHeight && pagingFlag) {
            pagingFlag = false;
            offset += LIMIT;
            searchGiphy();
        }
    }

    function closeGiphy() {
        $giphyWrap.style.display = null;
    }
}();