var GoogleAnalytics = (function () {

    return {
        load: load,
        initGoogleAnalytics4: initGoogleAnalytics4
    }

    function load(googleKey) {
        if (!googleKey) return;
        try {
            (function (a, s, y, n, c, h, i) {
                s.className += ' ' + y;
                h.end = i = function () {
                    s.className = s.className.replace(RegExp(' ?' + y), '')
                };
                (a[n] = a[n] || []).hide = h;
                setTimeout(function () {
                    i();
                    h.end = null
                }, c);
            })(window, document.documentElement, 'async-hide', 'dataLayer', 4000, {[googleKey]: true});
        } catch (e) {
            console.error('No Google Analytics tracking.');
        }
    }

    function initGoogleAnalytics4(gaKey) {
        if (!gaKey) return;
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', gaKey);
    }
})()
