var Caret = (function () {
    let macKeyEventFlag = false;

    var range, eraseEnd, eraseStart, node;
    var caretInfo = {};
    var lastFocusNode;

    let parentText, focusText, gapIdx, isGap, selectAllRange;

    return {
        setMacKeyEventFlag: setMacKeyEventFlag,
        getMacKeyEventFlag: getMacKeyEventFlag,

        focusBeforeCaret: focusBeforeCaret,
        focusNextCaret: focusNextCaret,
        getNode: getNode,
        changeAndFocusNode: changeAndFocusNode,
        changeFocusNode2Link: changeFocusNode2Link,
        getPosition: getPosition,
        getInput: getInput,
        collapse: setCaretCollapse,
        setCaretPositionAfterTag: setCaretPositionAfterTag,
        getCaretOffset: getCaretOffset,
        saveCaret: saveCaret,
        loadCaret: loadCaret,
        captureIndex: captureIndex,

        setLastFocusNode: setLastFocusNode,
        getLastFocusNode: getLastFocusNode,
        initLastFocusNode: initLastFocusNode,

        isTextInput: isTextInput,
        isParentMentionOrHashNode: isParentMentionOrHashNode,
        isMentionOrHashTag: isMentionOrHashTag,

        initSelectAllRange: initSelectAllRange,
        setSelectAllRange: setSelectAllRange,
        getCloneFragment: getCloneFragment,
        getFocusedInput: getFocusedInput,
        getSelectAllRange: getSelectAllRange,
        checkSelectAllRange: checkSelectAllRange,
        hasRangeSizzleElement: hasRangeSizzleElement,
    };

    function getMacKeyEventFlag() {
        return macKeyEventFlag;
    }
    function setMacKeyEventFlag(val) {
        macKeyEventFlag = val;
    }

    function setLastFocusNode(focusNode) {
        focusNode = focusNode || window.getSelection().focusNode;
        if (focusNode === undefined || focusNode === null) return;
        if ($(focusNode).hasClass('js-hidden-component')) return;
        lastFocusNode = focusNode
    }

    function getLastFocusNode() {
        return lastFocusNode;
    }

    function initLastFocusNode() {
        lastFocusNode = "";
    }

    function focusBeforeCaret() {
        var focusNode = window.getSelection().focusNode;
        var focusRange = window.getSelection().getRangeAt(0);
        var newRange = document.createRange();
        newRange.setStart(focusNode, focusRange.startOffset);
        var selection = document.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
    }

    function focusNextCaret(focusNode, offset) {
        if (!focusNode) return;
        var newRange = document.createRange();
        newRange.setStart(focusNode, offset);
        var selection = document.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
    }

    function getNode() {
        return {
            range: range,
            eraseStart: eraseStart,
            eraseEnd: eraseEnd,
            node: node,
        }
    }

    /**
     * Note.
     * changeAndFocusNode 실행 시점에 따라 가비지 텍스트 처리 안되는 경우있음.
     * settimeout(fn,0) 으로 항상 마지막에 실행되도록 함.
     */
    function changeAndFocusNode($changeSpan) {
        var textNode = getNode();
        if (textNode.node.nodeName !== "#text") return;
        /**
         * Note. 노드의 기준이 한칸 뒤가 되는 경우가 있음. 첫 스타트가 -1일때를 기준으로 더해줌.
         *  ex) 맥/일렉트론/특정 브라우저
         */

        var isIe = Often.isBrowser("ie")
        var hasStyleTag = hasStyleParents(node);
        if (hasStyleTag) {
            var parentNode = getParentNode($(textNode.node), isIe);

            var $cloneNode = $(parentNode).clone();
            var cloneNode = $cloneNode[0];

            //Note. 태그가 한겹 이상일 때 이슈, 계속 까서 텍스트가 나오면 하나씩 제하면서 동일한 위치가 나오면 거기 이후로 다 지움
            parentText = parentNode.textContent;
            focusText = textNode.node.textContent;
            gapIdx = parentText.indexOf(focusText);
            isGap = (parentText.length - focusText.length !== gapIdx);

            substrBackNodeValue(parentNode, textNode.eraseStart + (isGap ? gapIdx : 0));
            substrFrontNodeValue(cloneNode, textNode.eraseEnd + (isGap ? gapIdx : 0));
            // parentNode.innerText = parentNode.innerText.substr(0, textNode.eraseStart);
            // cloneNode.innerText = cloneNode.innerText.substr(textNode.eraseEnd);

            $(parentNode).after($changeSpan);
            $changeSpan.after($cloneNode);

        } else {
            const isContentEditableDiv = EditableUtil.isParentsFirstDivContentEditable(textNode.node);
            if (isContentEditableDiv) $(textNode.node).wrap(`<div></div>`);

            var isAdjustNeed = textNode.eraseStart === -1
            var eraseStart = isAdjustNeed ? textNode.eraseStart + 1 : textNode.eraseStart;
            var eraseEnd = isAdjustNeed ? textNode.eraseEnd + 1 : textNode.eraseEnd;
            var afterTextNode = textNode.node.splitText(eraseStart); //텍스트노드일때만 splitText 가능
            afterTextNode.deleteData(0, eraseEnd - eraseStart);
            isAdjustNeed && textNode.node.parentNode.insertBefore(document.createTextNode("\u00A0"), afterTextNode);
            textNode.node.parentNode.insertBefore($changeSpan[0], afterTextNode);
            textNode.node.parentNode.insertBefore(document.createTextNode("\u00A0"), afterTextNode);
        }

        /**
         * Note. 간헐적으로 가비지 텍스트가 남아서 임의로 처리해줌
         *  ex) "안녕하세요" => "<TAG>안녕하세요</TAG> 세요"
         *      불필요한 "세요"를 지워준다.
         */

        var temptText = $changeSpan.text();
        var nextNextEl = $changeSpan[0].nextSibling.nextSibling;
        var garbageText = Often.null2Void(nextNextEl && nextNextEl.textContent);
        if (garbageText !== " " && garbageText != "　" &&
            (garbageText.indexOf('@') > -1 ||
                garbageText.indexOf(temptText.substr(-1)) > -1 ||
                garbageText.indexOf(temptText.substr(-2)) > -1 ||
                garbageText.indexOf(temptText.substr(-3)) > -1) && Often.isBrowser("mac")) {
            nextNextEl.remove();
        }

        var nextOffset = getNextSiblingOffset($changeSpan[0].nextSibling)
        focusNextCaret($changeSpan[0].nextSibling, nextOffset);

        function getParentNode($textNode, isIe) {
            var $uploadArea = $textNode.closest(".js-upload-area");
            var hasPTag = $uploadArea.find(">p").length > 0;
            var tag = (isIe && hasPTag) ? "p>" : "div>";
            return $textNode.parents(tag)[0];
        }

        function getNextSiblingOffset(spanNextSibling, isIe) {
            var isTextNode = spanNextSibling.nodeName === "#text";
            var isEmptyNextSibling = Often.null2Void(spanNextSibling.textContent).length === 0;
            return (!isIe && !isTextNode && !isEmptyNextSibling ? 0 : 1);
        }
    }

    function changeFocusNode2Link(url) {
        var $link = $(TagUtil.link2tag(url));
        var selection = window.getSelection();
        var focusNode = selection.focusNode;
        var linkTextNode = focusNode.splitText(focusNode.textContent.indexOf(url));
        linkTextNode.deleteData(0, url.length);
        focusNode.parentNode.insertBefore($link[0], linkTextNode);
        if (Often.isBrowser("ie")) {
            $link.after(document.createTextNode("\u00A0"));
        } else {
            focusNode.parentNode.insertBefore(document.createTextNode("\u00A0"), linkTextNode);
        }
        focusNextCaret($link[0].nextSibling, 1);
    }

    function getPosition() {
        var sel, range, rects, rect;
        var x = 0,
            y = 0;
        sel = window.getSelection();
        if (!sel.rangeCount) return {x: 0, y: 0}
        range = sel.getRangeAt(0).cloneRange();
        range.collapse(true);
        rects = range.getClientRects();
        rect = rects.length > 0 ? rects[0] : "";
        x = Often.null2Void(rect.left, 0);
        y = Often.null2Void(rect.top, 0);
        return {x: x, y: y}
    }

    function getCaretOffset() {
        return window.getSelection().anchorOffset;
    }

    function setCaretPositionAfterTag() {
        var sel = window.getSelection();
        var range = document.createRange();
        if (Browser.ieYn && this.value) return range.move('character', this.value.length);
        range.setStart(sel.focusNode, 1);
        range.setEnd(sel.focusNode, 1);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        $(sel.focusNode).parents("div.editable").focus();
        if (Browser.chrome) {
            var korReg = /[ㄱ-힣ㅏ-ㅣ]/;
            setTimeout(function () {
                if (korReg.test(sel.focusNode.substringData(1, 1))) {
                    var cutLength = OS.isWin ? 2 : 1;
                    sel.focusNode.deleteData(1, cutLength);
                }
            })
        }
    }

    function setCaretCollapse() {
        var sel = window.getSelection();
        sel.rangeCount && sel.getRangeAt(0).collapse(true)
    }

    function getInput() {
        const {focusNode, focusOffset} = window.getSelection();
        var focusNodeData = Often.null2Void(Often.null2Void(focusNode).data);
        if (focusNodeData === "") return ""

        const inputFocusOffset = ElectronApi.Comm.isElectron() ? focusOffset + 1 : focusOffset;
        var inputValue = focusNodeData.substring(0, inputFocusOffset);
        var allInputWords = inputValue.split(/\s+/);
        var result = "";
        while (allInputWords.length > 0) {
            result = allInputWords.pop();
            if (Often.null2Void(result) !== "") return result;
        }
        return "";
    }

    function getFocusedInput() {
        const $focusNode = $(document.getSelection().focusNode);
        const attrName = "div[contenteditable=true]";
        const $parentsInput = $focusNode.parents(attrName);
        const $input = $focusNode.closest(attrName);
        const hasParentsInput = $parentsInput.length > 0;
        return hasParentsInput ? $parentsInput : $input;
    }

    function getFocusedElement() {
        var result = null;
        var sel = document.getSelection();
        if (sel.focusNode) {
            if (sel.focusNode.constructor === Text) {
                result = sel.focusNode
            }
        }
        return result;
    }

    function saveCaret() {
        caretInfo = {
            range: window.getSelection().getRangeAt(0).cloneRange(),
            offset: getCaretOffset(),
            focusElement: getFocusedElement(),
            inputObject: getFocusedInput(),
            inputLength: getInput().length,
        };
        return caretInfo;
    }

    function loadCaret() {
        var sel = document.getSelection();
        try {
            if (caretInfo.focusElement.constructor === Text) {
                caretInfo.range.setStart(caretInfo.focusElement.nextSibling.nextSibling, 1);
                caretInfo.range.collapse(false);
                sel.removeAllRanges();
                sel.addRange(caretInfo.range);
            }
        } catch (e) {
            console.error('error!', e);
        }
        caretInfo.inputObject.focus();
        caretInfo = null;
    }

    //key = @,#
    function captureIndex(key) {
        var sel = window.getSelection();
        try {
            range = sel.getRangeAt(0).cloneRange();
            var focusNodeData = sel.focusNode.data;
            try {
                if (ElectronApi.Comm.isElectron() && Often.getClientOSInfo().isWin && (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(focusNodeData.charAt(focusNodeData.length - 1)))) {
                    eraseEnd = sel.focusOffset + 1;
                } else {
                    eraseEnd = sel.focusOffset;
                }
            } catch (e) {
                eraseEnd = sel.focusOffset;
            }

            if (focusNodeData != null) {
                var tmpStr = focusNodeData.substring(0, eraseEnd);
                eraseStart = tmpStr.lastIndexOf(key);
            }

            node = sel.focusNode;
        } catch (e) {
            //pass
        }
    }

    function isChangeTextInput(e) {
        return (KeyCheck.isKey(e, "SPACE_BAR") || KeyCheck.isKey(e, "BACK") || KeyCheck.isKey(e, "DELETE"));
    }

    function isTextInput(e) {
        return !(isChangeTextInput(e) ||
            e.key === "Shift" || e.key === "Alt" || e.key === "Control" || e.key === "Meta" || e.key === "Fn" ||
            e.key === "ScrollLock" || e.key === "NumLock" || e.key === "CapsLock" ||
            KeyCheck.isKey(e, "ENTER") || KeyCheck.isKey(e, "TAB") || KeyCheck.isKey(e, "ESC") ||
            KeyCheck.isKey(e, "UP") || KeyCheck.isKey(e, "RIGHT") ||
            KeyCheck.isKey(e, "DOWN") || KeyCheck.isKey(e, "LEFT") ||
            e.key === "End" || e.key === "Home" || e.key === "PageDown" || e.key === "PageUp" ||
            e.key === "Clear" || e.key === "Insert" || e.key === "PrintScreen" || e.key === "Pause" ||
            e.key === "Win" || e.key === "Apps" || e.key === "KanjiMode" ||
            e.key === "KanaMode" || e.key === "Standby");
    }

    /**
     * @param focusNode
     * @description 현재 노드의 부모태그가 멘션 혹은 해시태그 내부 노드인지 체크
     */
    function isParentMentionOrHashNode(focusNode) {
        if (!focusNode) return false;
        var focusParentNode = focusNode.parentNode;
        var isParentSpanNode = (focusParentNode && focusParentNode.nodeName === "SPAN");
        var isParentNodeSpanClass = isParentSpanNode && ($(focusParentNode).hasClass("mention-span") || $(focusParentNode).hasClass("hashtag-span"));
        return isParentNodeSpanClass;
    }

    /**
     * @param focusNode
     * @description 현재 노드가 멘션 혹은 해시태그 내부 노드인지 체크
     */

    function isMentionOrHashTag(focusNode) {
        if (!focusNode) return false;
        var isFocusTextNode = (focusNode.nodeName === "#text")
        var isFocusNodeSpanClass = isFocusTextNode && ($(focusNode).hasClass("mention-span") || $(focusNode).hasClass("hashtag-span"));
        return isFocusNodeSpanClass;
    }

    function hasStyleParents(node) {
        var hasStyleTag = false;
        var $parents = $(node).parents();
        var styleTag = ["B", "U", "I", "STRIKE", "EM", "STRONG"];
        $.each($parents, function (i, node) {
            var nodeName = Often.null2Void(node.nodeName).toUpperCase();
            if (styleTag.indexOf(nodeName) > -1) {
                hasStyleTag = true;
                return false;
            }
        });
        return hasStyleTag;
    }

    //노드 텍스트 뒤 자르기
    function substrBackNodeValue(parentNode, startIdx) {

        let textLength = 0;
        let cycle = 0;
        checkEndNode(parentNode, startIdx);

        function checkEndNode(node, idx) {
            if (cycle > 100) return;
            cycle++;
            Array.prototype.forEach.call(node.childNodes, function(_node, v) {
                if (_node.nodeName === "#text") {
                    if (textLength === -1) {
                        _node.nodeValue = "";
                    } else if (textLength + _node.nodeValue.length <= idx) {
                        textLength += _node.nodeValue.length;
                    } else {
                        _node.nodeValue = _node.nodeValue.substring(0, idx - textLength);
                        textLength = -1;
                    }
                } else {
                    checkEndNode(_node, idx);
                }
            })
            // node.childNodes.forEach(function (_node) {
            //     if (_node.nodeName === "#text") {
            //         if (textLength === -1) {
            //             _node.nodeValue = "";
            //         } else if (textLength + _node.nodeValue.length <= idx) {
            //             textLength += _node.nodeValue.length;
            //         } else {
            //             _node.nodeValue = _node.nodeValue.substring(0, idx - textLength);
            //             textLength = -1;
            //         }
            //     } else {
            //         checkEndNode(_node, idx);
            //     }
            // })
        }
    }

    //노드 텍스트 앞 자르기
    function substrFrontNodeValue(parentNode, endIdx) {

        let textLength = 0;
        let cycle = 0;
        checkStartNode(parentNode, endIdx);

        function checkStartNode(node, idx) {
            if (cycle > 100) return;
            cycle++;
            Array.prototype.forEach.call(node.childNodes, function(_node, v) {
                if (_node.nodeName === "#text") {
                    if (textLength === -1) {
                        //pass
                    } else if (textLength + _node.nodeValue.length <= idx) {
                        textLength += _node.nodeValue.length;
                        _node.nodeValue = "";
                    } else {
                        _node.nodeValue = _node.nodeValue.substring(idx - textLength);
                        textLength = -1;
                    }
                } else {
                    checkStartNode(_node, idx);
                }
            });
            // node.childNodes.forEach(function (_node) {
            //     if (_node.nodeName === "#text") {
            //         if (textLength === -1) {
            //             //pass
            //         } else if (textLength + _node.nodeValue.length <= idx) {
            //             textLength += _node.nodeValue.length;
            //             _node.nodeValue = "";
            //         } else {
            //             _node.nodeValue = _node.nodeValue.substring(idx - textLength);
            //             textLength = -1;
            //         }
            //     } else {
            //         checkStartNode(_node, idx);
            //     }
            // })
        }
    }

    function initSelectAllRange() {
        selectAllRange = "";
    }

    function setSelectAllRange() {
        const selection = window.getSelection();
        if (!selection.rangeCount) return "";
        selectAllRange = selection.getRangeAt(0);
    }

    function getSelectAllRange() {
        return selectAllRange;
    }

    /**
     * 선택된 Range에 ChildNodes 반환
     */
    function getCloneFragment(range) {
        const selection = window.getSelection();
        const selectRange = range || selection.getRangeAt(0);
        return selectRange.cloneContents();
    }

    /**
     * keyup, mouseup 이벤트에서 사용
     * ContentEditable에서 선택된 Range를 selectAllRange 변수에 저장
     */
    function checkSelectAllRange() {
        setTimeout(function () {
            const $focused = getFocusedInput();
            if ($focused.length > 0) {
                setSelectAllRange();
                return;
            }
            initSelectAllRange();
        }, 0);
    }

    function hasRangeSizzleElement(range, sizzleName) {
        return $(Caret.getCloneFragment(range)).find(sizzleName).length > 0;
    }
})();
