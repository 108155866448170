var Company = (function () {

    var CompJson = {};

    return {
        initSetting: initSetting,
        initSettingV2: initSettingV2,
        drawServiceBlock: drawServiceBlock,
        drawKrxUpdate: drawKrxUpdate,
        controlPopup: controlPopup,
        drawConsultPopup: consultPopup,
        test: function () {
            drawServiceBlock(BLOCK_TYPE.SERVICE_ADMIN, movePayPage, drawLogOutPopup);
            $("#tempPopup").addClass("serviceStop");
        },
    };

    function setCompanyJson(dat) {
        var buyCode = Often.null2Void(dat.BUY_YN, "N");
        var statusCode = Often.null2Void(dat.STTS, "N");
        var remainDate = Often.null2Void(dat.GRC_DT, "7");
        var userCnt = Number(Often.null2Void(dat.USER_CNT, "1"));

        CompJson.isBflow = (_USE_INTT_ID.indexOf("BFLOW_") === 0)
        CompJson.isUtlz = (_USE_INTT_ID.indexOf("UTLZ_") === 0)
        CompJson.isFlow = (_USE_INTT_ID.indexOf("FLOW_") === 0)
        CompJson.isGoogle = (_USE_INTT_ID.indexOf("GMAIL_") === 0)
        CompJson.isGoogleAuth = Often.getCookie('googleLoginYn') === 'Y'

        CompJson.isAvailableService = (Often.null2Void(dat.USE_YN, "Y") === "Y");
        CompJson.isManager = (Often.null2Void(dat.MNGR_DSNC, "N") === "Y");
        CompJson.isDailyOnce = (Often.isServerModeByHost("DEV_TEST") || (Often.null2Void(dat.DAILY_INIT_YN, "N") === "Y"));
        CompJson.isDvsnTree = (Often.null2Void(dat.DVSN_TREE_YN, "N") === "Y");
        CompJson.isCardBanner = (Often.null2Void(dat.CARD_BANNER_YN, "N") === "Y");
        CompJson.isSerp = (Often.null2Void(dat.SERP_YN, "N") === "Y");
        CompJson.isPreventDownloadMobile = (Often.null2Void(dat.MB_DOWN_YN, "N") === "Y");
        CompJson.isPreventDownloadPC = (Often.null2Void(dat.PC_DOWN_YN, "N") === "Y");
        CompJson.isEnterSns = (Often.null2Void(dat.ENT_YN, "N") === "Y" && (_USE_INTT_ID.indexOf("GMAIL_") === 0 || _USE_INTT_ID.indexOf("KAKAO_") === 0 || _USE_INTT_ID.indexOf("APPLE_") === 0));
        CompJson.expireDttm = Often.null2Void(dat.EXPIRE_DTTM)

        if (Often.isFunc("USE_CHAT_BOT_BUTTON") && ElectronApi.Comm.isElectronApp()) {
            if (ServerChecker.isKyowon) KyowonConfig.miniLeftChatBotButtonShowOrHide(dat.GROUP_CD);
        }

        if (Often.isFunc("CHAT_AUDITOR_ALL")) CompJson.isChatAuditor = Often.null2Void(dat.CHAT_AUDITOR_YN, "N");


        CompJson.buyCode = buyCode;
        CompJson.statusCode = statusCode;
        CompJson.remainDate = remainDate;
        CompJson.userCnt = userCnt;

        LocalUtil.setLocalJson('ONLY_COMPANY_JSON', CompJson);
        SentryMonitor.setExtra("COMPANY_INFO", CompJson);
    }

    function initSetting(dat) {
        setCompanyJson(dat);
        controlSetting(); //세팅
        controlButton();  //버튼
        controlTopBanner(); //상단배너
        controlPopup(); //팝업
    }

    function initSettingV2() {
        controlSetting(); //세팅
        controlButton();  //버튼
        controlTopBanner(); //상단배너
        controlPopup(); //팝업
    }

    function controlButton() {
        const CompJson = LocalUtil.getLocalJson('ONLY_COMPANY_JSON') || {};
        var isPaidCompany = CompJson.buyCode === BuyCode.PAID_COMPANY && !Often.isFunc("HIDE_OPEN_PROJ");
        const isShowAdminBtn = (CompJson.isManager && (CompJson.isBflow || CompJson.isUtlz || (_ENTER_YN === "Y")));
        const isShowInviteMemberBtn = CompJson.isBflow;
        if (ServerChecker.isPsnm && PsnmConfig.isTemporaryEmplInfm()) isPaidCompany = false;
        var isLeftControl = {
            'open': (isPaidCompany),
            // 'service-upgrade': (!isPaidCompany && !isFreelancer),
            'manager-admin': isShowAdminBtn,
            'invite-member': isShowInviteMemberBtn,
            'kyowon-notice': ServerChecker.isKyowon && Often.isFunc("KYOWON_NOTICE"),
            'kyowon-banner': ServerChecker.isKyowon && Often.isFunc("KYOWON_BANNER"),
            'admin-notice': Often.isFunc("ADMIN_NOTICE"),
            'dashboard-banner': Often.isFunc("DASHBOARD_BANNER"),
            "auditor-chat-all": ServerChecker.isKyowon && !CompJson.isManager && Often.isFunc("CHAT_AUDITOR_ALL") && (CompJson.isChatAuditor === 'Y'),
            "flow-guest": Often.isFunc("MAIN_GUEST"),
        }

        //조직도
        const isDisabledOrgChart = Often.isFunc("DISABLED_ORG_CHART");
        const isOrgManagement = Often.isFunc("ORG_CHART_MANAGEMENT") && !isDisabledOrgChart;
        const isActiveOrgChart = LocalUtil.getLocal("ONLY_ORG_CHART_ACTIVE_YN") === "Y";
        const isShowOrgBtn = CompJson.isBflow && ((CompJson.isManager && !(CompJson.isDvsnTree && !isActiveOrgChart))
            || (!CompJson.isManager && CompJson.isDvsnTree && isActiveOrgChart));

        var isTopControl = {
            'organizationTopButton': (((!isOrgManagement || !CompJson.isBflow) && CompJson.isDvsnTree)
                || (isOrgManagement && (isShowOrgBtn || CompJson.statusCode === StatusCode.UN_BFLOW.GUEST) && isOrgManagement) || (_ENTER_YN === "Y")) && !Often.isFunc(Func.ENTER.HIDE_ORG_BTN) && !CompJson.isEnterSns,
            ...(ServerChecker.isEnter ? {'portalTopButton': CompJson.isPortalMenu} : {}),
            'groupWareBtn' : ServerChecker.isKyowon
        }

        //서비스 업그레이드, 결제, 탈퇴하기
        var isMySettingControl = {
            'upgradeBanner': (CompJson.statusCode === StatusCode.UN_BFLOW.GUEST),
            'companyParticipationBtn': (CompJson.statusCode === StatusCode.UN_BFLOW.GUEST && (CompJson.isFlow || CompJson.isGoogle)),
            'paymentLeftMenu': (CompJson.buyCode === BuyCode.FREELANCER),
            'leaveFlowBtn': (CompJson.statusCode === StatusCode.UN_BFLOW.GUEST || CompJson.buyCode === BuyCode.FREELANCER || CompJson.isEnterSns),
            'passwordArea': !CompJson.isGoogleAuth && (CompJson.isUtlz || CompJson.isFlow || CompJson.isBflow || Often.isFunc(Func.ENTER.PWD_EDIT) || (ServerChecker.isKogas && KogasConfig.isTemporaryEmplInfm())),
            'bizplayPasswordInput': CompJson.isUtlz,
            'normalPasswordInput': Func.ENTER.PWD_REG_MORE_THAN_EIGHT,
        }

        var $topBtns = $("#rightTopMenu");
        var $mySettingPopup = $("#mySettingPopup");

        for (var key in isTopControl) {
            $topBtns.find("#" + key).css('display', isTopControl[key] ? 'inline-block' : 'none');
        }

        for (var key in isMySettingControl) {
            $mySettingPopup.find("#" + key).css('display', isMySettingControl[key] ? 'flex' : 'none');
        }

        //채팅 감사자 "FLOW_AUDITOR_R006" ???
        if (!isShowAdminBtn && !isShowInviteMemberBtn && !Often.isFunc('DASHBOARD_BANNER')) {
            const $leftBottomUl = $("#leftBottomUl");
            if ($leftBottomUl.children("li").length > 0) return;
            $leftBottomUl.css("display", "none");
        }
    }

    function controlTopBanner() {
        const CompJson = LocalUtil.getLocalJson('ONLY_COMPANY_JSON') || {};
        if (CompJson.isCardBanner && !_IsMini) {
            Top.setBanner("end-time");
        }
    }

    async function controlSetting() {
        let jbclNm = "";
        let dvsnNm = "";
        let fullTextVersion = "", textVersion = "";
        if (_USE_INTT_ID === "KRX_1") {
            await Ajax.executeApi(RestApi.GET.COLABO2_USER_PRFL_R002, {_USER_ID}, function (dat) {
                dvsnNm = dat.DVSN_NM;
                jbclNm = dat.CMNM;
                if (dvsnNm === null) dvsnNm = "";
                if (jbclNm === null) jbclNm = "";
                fullTextVersion += jbclNm;
                if (dat.CMNM !== null && dat.DVSN_NM !== null) fullTextVersion += ",";
                fullTextVersion = dvsnNm;
            });
        } else {
            fullTextVersion = i18next.t(common.version, {val: $t(getVersionName())});
        }
        textVersion = PlanFunc.isMorningmateAppSumo() ? PlanFunc.getPlanInfo().PLAN_NAME : i18next.t(common.version, {val: $t(getVersionName())});
        $("#versionMessage").html(Interpolation.breakLine(i18next.t(main.top.toolTipBusinessFree, {
            val: $t(getVersionName()),
            count: LocalUtil.getBuyGrcDt()
        })));
        $("#versionLabel").html(Interpolation.breakLine(i18next.t(main.top.businessFree, {val: $t(getVersionName()),})));
        $("#mySettingPopup").find("#version").text(PlanFunc.isMorningmateAppSumo() ? PlanFunc.getPlanInfo().PLAN_NAME : fullTextVersion);
        $("#accountLayer").find(".js-version").text(_USE_INTT_ID === 'KRX_1' ? fullTextVersion : _USE_INTT_ID === 'KSFC_1' ? window._DVSN_NM : textVersion);

        if (ElectronApi.Comm.isElectron()) {
            !ElectronApi.Comm.getConfigs().b_fullMode && $('#logoutBtn').css('display', 'none');
            $('#prevVersionBtn, #miniOpenBtn, #desktopDownloadBtn').css('display', 'none');
        }

        function getVersionName() {
            const CompJson = LocalUtil.getLocalJson('ONLY_COMPANY_JSON') || {};
            var isGuest = CompJson.statusCode === StatusCode.UN_BFLOW.GUEST || (CompJson.isSerp && CompJson.isCardBanner);
            var isFreelancer = CompJson.buyCode === BuyCode.FREELANCER;
            const isFuncHideBasic = Mutil.isFunc("HIDE_BASIC");
            const {isKtWorks, isKyungrinara, isDatapuree, isHiib} = ServerChecker;
            if (_ENTER_YN === 'Y') return main.stripe.enterPlan;
            if (isKtWorks) return KtWorksConfig.get("VERSION_NAME");
            if (isKyungrinara) return Kyungrinara.getVersionName(isGuest);
            if (isDatapuree) return Datapuree.getVersionName(isGuest);
            if (PlanFunc.isUsePlan()) {
                if(Often.isGlobal()) {
                    if(PlanFunc.isMorningBasic()) return dictionary.basic;
                    if(PlanFunc.isMorningPro()) return dictionary.pro;
                    return dictionary.businessPlus;
                }
                return (isGuest ? dictionary.guest : PlanFunc.isPro() ? (isFuncHideBasic ? dictionary.business : dictionary.businessPro) : dictionary.businessBasic)
            }
            return (isGuest ? dictionary.guest : isFreelancer ? dictionary.freelance : dictionary.businessBasic)
        }
    }

    function controlPopup() {

        if ($('#paymentLayer').is(':visible')) return;

        const CompJson = LocalUtil.getLocalJson('ONLY_COMPANY_JSON') || {};

        if(Often.isFunc("PHONE_GET_POP") && Often.isGlobal()) { //글로벌 미니 핸드폰번호 입력전 사용불가 팝업
            controlPhoneGetPop(Often.isAct("miniMain"));
        }

        //결제 권유 팝업
        if (CompJson.statusCode === StatusCode.BFLOW.REMAIN_WEEK && CompJson.isManager && CompJson.isDailyOnce) {
            drawRecommendPayPopup(CompJson.remainDate);
            return;
        }

        if (CompJson.statusCode === StatusCode.BFLOW.END_ADMIN && CompJson.isAvailableService) {
            drawServiceBlock(BLOCK_TYPE.SERVICE_ADMIN, movePayPage, drawLogOutPopup);
            $("#tempPopup").addClass("serviceStop");
            throw {name: '서비스중지 - 관리자', code: CompJson.statusCode};
        }

        if (CompJson.statusCode === StatusCode.BFLOW.END_USER) {
            Often.logoutDirect(true);
            drawServiceBlock(BLOCK_TYPE.SERVICE_USER, Often.logoutDirect, drawLogOutPopup);
            $("#tempPopup").addClass("serviceStop");
            throw {name: '서비스중지 - 유저', code: CompJson.statusCode};
        }

        if (!CompJson.isAvailableService) {
            Often.logoutDirect(true);
            drawServiceBlockPopup();
            throw {name: '이용중지'};
        }
        if (CompJson.statusCode.includes("SP")) {
            let context;
            switch (CompJson.statusCode) {
                case StatusCode.STRIPE.AUTO_DOWNGRADE_TO_BASIC.ADMIN:
                    return openAutoDowngradeBasic(BLOCK_TYPE.STRIPE.AUTO_DOWNGRADE_TO_BASIC);
                case StatusCode.BEFORE_TRIAL:
                    if (Often.isAct('miniMain')) {
                        drawServiceBlock(BLOCK_TYPE.BEFORE_TRIAL, function () {
                            ElectronApi.Project.open({url: Often.getLocOrigin() + '/main.act', isInit: false});
                        }, () => drawLogOutPopup('',i18next.t(main.alert.logout)))
                    } else {
                        new OnboardPop()?.render();
                        Often.setCookie('DESKTOP_GUIDE_SHOW', 'Y_' + window._USER_ID);
                        IntercomHelper.drawButton(false); //무조건 draw
                    }
                    return;
                case StatusCode.STRIPE.SEAT.EXCEED_GRACEDAY_ADMIN :
                    drawServiceBlock(BLOCK_TYPE.STRIPE.SEAT.EXCEED_GRACEDAY_ADMIN, () => {
                        window.open('/stripe_customer_portal.act', '_self');
                    }, drawLogOutPopup);
                    $("#tempPopup").addClass("serviceStop");
                    return;
                case StatusCode.STRIPE.SEAT.EXCEED_GRACEDAY :
                    return openUserEndPopup(BLOCK_TYPE.STRIPE.SEAT.EXCEED_GRACEDAY);
                case StatusCode.STRIPE.SEAT.EXCEED :
                    context = BLOCK_TYPE.STRIPE.SEAT.EXCEED;
                    context.main = i18next.t(main.stripe.updatePlanGrace, {val: Time.format(CompJson.expireDttm, 'type2')});
                    drawServiceBlock(context, () => {
                        window.open('/stripe_customer_portal.act', '_self');
                    }, drawLogOutPopup);
                    $("#tempPopup").addClass("serviceStop");
                    return;
                case StatusCode.STRIPE.FREE_TRIAL_END.ADMIN:
                    return openAdminEndPopup(BLOCK_TYPE.STRIPE.FREE_TRIAL_END.ADMIN);
                case StatusCode.STRIPE.FREE_TRIAL_END.USER:
                    context = Often.isFunc("AUTO_DOWNGRADE_TO_BASIC_PLAN") ?
                        BLOCK_TYPE.STRIPE.GRACE_DAY_END.AUTO_DOWNGRADE_TO_BASIC : BLOCK_TYPE.STRIPE.GRACE_DAY_END.USER
                    return openUserEndPopup(context);
                case StatusCode.STRIPE.GRACE_DAY_END.ADMIN :
                    context = BLOCK_TYPE.STRIPE.GRACE_DAY_END.ADMIN;
                    context.main = i18next.t(context.main, {val: Time.format(CompJson.expireDttm, 'type2')});
                    return openAdminEndPopup(context);
                case StatusCode.STRIPE.GRACE_DAY_END.USER :
                    context = Often.isFunc("AUTO_DOWNGRADE_TO_BASIC_PLAN") ?
                        BLOCK_TYPE.STRIPE.GRACE_DAY_END.AUTO_DOWNGRADE_TO_BASIC : BLOCK_TYPE.STRIPE.GRACE_DAY_END.USER
                    return openUserEndPopup(context);
                case StatusCode.STRIPE.GRACE_DAY.ADMIN :
                    context = BLOCK_TYPE.STRIPE.GRACE_DAY.ADMIN
                    context.main = i18next.t(context.main, {val: Time.format(CompJson.expireDttm, 'type2')});
                    drawServiceBlock(context, () => {
                        window.open('/stripe_customer_portal.act', '_self');
                    });
                    return;
            }
            return;
        }

        //Todo. 유효기간 만료
        if (CompJson.statusCode === "R") {
            return;
        }
    }

    function openAdminEndPopup(context) {
        if($('.limit-popup').is(':visible')) return;
        infoPopup();
        function infoPopup() {
            PopupDraw.drawBlock({
                await: true,
                type_layer:true,
                class: 'service',
                contents: context,
                callback: {
                    submit: () => {
                        if (ElectronApi.Comm.isElectron() && _IsMini) {
                            ElectronApi.Project.open({url: Often.getLocOrigin() + '/main.act?stripePayment', projectSrno: 'paymentPage'});
                        } else {
                            StripePayment.showPopup();
                        }
                    },
                    bottom: () => {
                        consultPopup()
                    },
                    cancel: () => {
                        drawLogOutPopup(infoPopup)
                    },
                    close: () => {
                        drawLogOutPopup(infoPopup)
                    }
                }
            })
        }
    }

    function consultPopup() {
        PopupDraw.drawConsultPopup({
            await: true,
            enter_prevent: true,
            type_layer: true,
            contents: BLOCK_TYPE.STRIPE.CONSULT,
            callback: {
                submit: ($popup) => {
                    const _writePost = new Mutil.DebounceNThrottle(writePost, 300);
                    const text = $popup.find("#popupTextArea").val();
                    _writePost.debounceAfterStart({
                        text: text, successCallBack: () => {
                            PopupDraw.closePopup();
                        }
                    });
                },
                final: ($popup) => {
                    $popup.css("z-index", "100000");
                }
            },
        })
    }

    function openAutoDowngradeBasic(context) {
        if($('.limit-popup').is(':visible')) return;
        infoPopup();
        LocalUtil.setLocalValue('ONLY_COMPANY_JSON', 'statusCode', 'S');

        function infoPopup() {
            PopupDraw.drawBlock({
                await: true,
                type_layer:true,
                class: 'service',
                contents: context,
                callback: {
                    bottom: () => {
                        StripePayment.showPopup();
                    },
                }
            })
        }
    }

    function openUserEndPopup(context) {
        if($('.limit-popup').is(':visible')) return;
        infoPopup();

        function infoPopup() {
            PopupDraw.drawBlock({
                await: true,
                type_layer: true,
                class: 'service',
                contents: context,
                callback: {
                    submit: () => {
                        openRequestAdminPopup(infoPopup);
                    },
                    cancel: () => {
                        drawLogOutPopup(infoPopup)
                    },
                    close: () => {
                        drawLogOutPopup(infoPopup)
                    },
                }
            })
        }
    }

    function openRequestAdminPopup(closeCallBack) {
        PopupDraw.drawConsultPopup({
            contents: Often.isFunc("AUTO_DOWNGRADE_TO_BASIC_PLAN") ?
                BLOCK_TYPE.STRIPE.FREE_TRIAL_END.AUTO_DOWNGRADE_TO_BASIC : BLOCK_TYPE.STRIPE.FREE_TRIAL_END.USER,
            enter_prevent: true,
            type_layer: true,
            callback: {
                submit: ($popup) => {
                    const _sendEmail = new Mutil.DebounceNThrottle(sendEmail, 300);
                    const text = $popup.find("#popupTextArea").val();
                    _sendEmail.debounceAfterStart(sendEmail({
                        text: text, successCallBack: () => {
                            PopupDraw.closePopup();
                            (typeof closeCallBack === 'function') && closeCallBack();
                        }
                    }));
                },
                cancel: () => {
                    drawLogOutPopup(closeCallBack)
                },
                close: () => {
                    (typeof closeCallBack === 'function') && closeCallBack();
                },
            },
        })
    }

    function writePost({text, successCallBack}) {
        const input = {
            CMNM: _USE_INTT_NM,
            USER_NM: _USER_NM,
            EML: _USER_EMAIL,
            CLPH_NO: "12345678",
            CONTACT_TYPE: "FREE_TRIAL",
            ROUTE: location.pathname,
            TYPE: "FREE_TRIAL",
            CONTACT_CNTN: `
                    [Free Trial Request]
					- Current Subscription : ${PlanFunc.getPlanInfo().PLAN_NAME} (${PlanFunc.getPlanInfo().PLAN_ID})
					- Company Name : ${_USE_INTT_NM} (${_USE_INTT_ID})
					- Name : ${_USER_NM} (${_USER_ID})
					- Customer Note : ${text}
            `.trim().replace(/\t/g, ''),
            packetOption: Ajax.OPTION.PREVENT_EXECUTE
        };
        Ajax.executeApi("FLOW_CNTT_C001", input, function () {
            Often.toast("success", i18next.t(main.alert.completeRequest));
            (typeof successCallBack === 'function') && successCallBack();
        });
    }

    function sendEmail({text, successCallBack}) {
        const input = {
            USE_INTT_ID: _USE_INTT_ID,
            RGSN_DTTM: _RGSN_DTTM,
            CNTN: text,
            packetOption: Ajax.OPTION.PREVENT_EXECUTE
        }
        Ajax.executeApi("FLOW_SEND_SESMAIL", input, function () {
            Often.toast("success", i18next.t(main.alert.completeRequest));
            (typeof successCallBack === 'function') && successCallBack();
        });
    }

    function drawLogOutPopup(callback, confirmMsg = i18next.t(main.alert.redirectLogin)) {
        if (confirm(confirmMsg)) {
            Often.logoutDirect();
        } else {
            (typeof callback === "function") && callback();
            Company.controlPopup();
        }

        // 플로우 팝업을 사용하는 코드 (ESC와 클릭 연타하면 뚫림)
        /*
        PopupDraw.drawConfirm({
            contents: {main: i18next.t(main.alert.redirectLogin)},
            callback: {
                submit: Often.logoutDirect,
                close: function () {
                    (typeof callback === "function") && callback();
                    Company.controlPopup();
                },
            }
        });
        $("#tempPopup").css('z-index', 20);
        */
    }

    function movePayPage($currentPopup) {
        if (ElectronApi.Comm.isElectron() && _IsMini) {
            ElectronApi.Project.open({url: Often.getLocOrigin() + '/main.act', projectSrno: 'paymentPage'});
            // Electron.openElectronProject(Often.getLocOrigin() + '/main.act', 'paymentPage');
        } else {
            if (Often.isGlobal()) StripePayment.showPopup();
            else Payment.openPaymentLayer("business");
        }

        PopupDraw.closePopup($currentPopup);
    }

    function drawServiceBlockPopup() {

        if (ServerChecker.isEland) {
            drawServiceBlock($.extend({}, BLOCK_TYPE.USE, {main: i18next.t(main.alert.contact, {val: "ITHELP@ELAND.CO.KR"})}));
            return;
        }

        if (Often.isFunc(Func.CLOUD.RESTRICT_POPUP_MANAGER)) {
            Ajax.executeApi(RestApi.GET.COLABO2_MNGR_R001, {}, function (dat) {
                var strAdministrator = "";
                dat.MNGR_REC && $.each(dat.MNGR_REC, function (i, v) {
                    if (i > 0) strAdministrator += ', ';
                    var isExistName = (Often.null2Void(v.USER_NM, "") !== "");
                    isExistName && (strAdministrator += i18next.t(common.user, {user: v.USER_NM}))
                });
                drawServiceBlock($.extend({}, BLOCK_TYPE.USE,
                    {main: i18next.t(main.alert.askToAdmin, {val: strAdministrator})}));
            })
            return;
        }

        drawServiceBlock(BLOCK_TYPE.USE, drawLogOutPopup, drawLogOutPopup);
        $("#tempPopup").addClass("serviceStop");
    }

    function drawRecommendPayPopup(remainDate) {
        var dataJson = $.extend({}, BLOCK_TYPE.EXPERIENCE);
        var isPlusRemainDate = Number(remainDate) > -1;
        isPlusRemainDate && (dataJson.title = getEndTimeComment(remainDate));
        drawServiceBlock(dataJson, movePayPage);
    }

    function drawServiceBlock(blockContents, submitCallback, closeCallback, blockInfoLink) {
        if (Often.isFunc("FLOW_DESKTOP_UPDATE_BLOCK")) return;
        if (!_IsMini) PopupDraw.closePopup();
        const _submitCallback = new Mutil.DebounceNThrottle(submitCallback, 300);
        PopupDraw.drawBlock({
            await: true,
            type_layer: true,
            class: getBlockPopupClass(blockContents),
            contents: blockContents,
            linkUrl: blockInfoLink,
            callback: {
                submit: _submitCallback.debounceAfterStart,
                close: closeCallback,
                final: function ($currentPopup) {
                    $currentPopup.css("z-index", "100000");
                },
            },
            closeButtonYn: blockContents === BLOCK_TYPE.DESKTOP_FORCE_UPDATE ? "Y" : "N"
        })
    }

    function getBlockPopupClass(blockContents) {
        if (blockContents === BLOCK_TYPE.STOP_FLOWNEW || blockContents === BLOCK_TYPE.DESKTOP_UPDATE) {
            return 'invite'
        }

        if (blockContents === BLOCK_TYPE.DESKTOP_FORCE_UPDATE) {
            return 'no-logo-forceUpdate'
        }

        if (blockContents === BLOCK_TYPE.DESKTOP_SELECT_UPDATE) {
            return 'no-logo-selectUpdate'
        }

        if (blockContents === BLOCK_TYPE.SERVICE_ADMIN) {
            return 'service free-trial-ended'
        }

        if (blockContents === BLOCK_TYPE.SERVICE_USER) {
            return 'service temp-logout'
        }

        return 'service'
    }

    function drawKrxUpdate(json) {
        if (!_IsMini) PopupDraw.closePopup();
        PopupDraw.drawKrxUpdate(json);

    }

    function getEndTimeComment(remainDate) {
        if (Number(remainDate) < 0) return i18next.t(main.alert.trialEnd, {
            version: $t(common.businessBasic)
        });
        return i18next.t(main.alert.trialExpiredDate, {
            version: PlanFunc.getPlanName(),
            count: remainDate
        });
    }

    function controlPhoneGetPop(isMiniMain) {
        if (isMiniMain) $('#desktopGuideLayer').hide();
        if (!isMiniMain || !ElectronApi.Comm.isElectronAvailable()) return;
        if (LocalUtil.getLocalJson('ONLY_BUY_SETTING')?.PHONE_SET_BEFORE_TRIAL_YN === 'N') {
            drawServiceBlock({
                title: main.phoneNumber.helpInitialNotice,
                main: i18next.t(main.phoneNumber.helpNotice,
                    {val: Often.isGlobal() ? $t(dictionary.morningmate) : $t(dictionary.flow)}),
                submit: dictionary.confirm,
            }, function () {
                ElectronApi.Project.open({url: Often.getLocOrigin() + '/main.act', isInit: false});
            }, () => drawLogOutPopup('', i18next.t(main.alert.logout)));
        }
    }

})();
