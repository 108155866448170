var GoogleMeet = function () {

    return {
        openSignIn : openSignIn,
    }

    /**
     * @description access, refresh 토큰 중 둘중 하나라도 불완전 할시
     */
    function openSignIn() {
        Ajax.executeApi(RestApi.POST.FLOW_GOOGLE_AUTH_C001, {
            EXT_SERVICE_NM : "GOOGLE_MEET",
            USER_ID: _USER_ID,
            RGSN_DTTM: _RGSN_DTTM,
        }, function (data) {
            if (ElectronApi.Comm.isElectron()) {
                ElectronApi.Window.open({
                    url: data.CONNECT_URL,
                    title: '_target',
                    width: '500px',
                    height: '700ox',
                    frame: true,
                    x: '100',
                    y: '100'
                });
            } else {
                window.open(data.CONNECT_URL, "_blank", "width=500px,height=700px,top=100,left=100");
            }
        })
    }
}();
