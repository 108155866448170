var InputtingTest = (function () {

    const password = `qwer1234!`;

    return {
        drawButton: drawButton,
    }

    function drawButton() {
        return;s

        /*
        if (window._ENTER_YN === 'Y') return;
        if (Often.isServerModeByHost("REAL") || Often.isGlobal()) return;

        var $button = $(`<div><i></i></div>`);
        $button.css({'position': 'fixed', 'top': '40%', 'cursor': 'pointer', 'z-index': 999999})
        $button.find("i").css({
            'display': 'block',
            'width': '70px',
            'height': '70px',
            'background': 'url(https://flow.team/flow-renewal/view/homepage/assets/images/common/icon_help.svg) center no-repeat'
        });
        $button.on("click", clickButton);
        $("body").append($button);
        */
    }



    function clickButton(e) {
        Often.toast("success", `이 버튼은 운영에서 노출되지 않고 상황에 맞게 도와줄거에요!`);
        if (location.pathname.includes("main")) {
            if ($("#paymentLayer").is(":visible")) return inputtingMainOnPayment();
            if ($("#flowUpgradeStep").is(":visible")) return inputtingTeamInfo('upgrade');
        }
        if (location.pathname.includes("signin")) {
            return inputting({
                "userId": getSavingEmail(),
                "password": password
            })
        }
        if (location.pathname.includes("corpsignup")) return inputtingSignupOnBflow();
        if (location.pathname.includes("signup")) {
            if ($("#guestStep").is(":visible")) return inputtingSignupOnGuest();
            if ($("#userStep").is(":visible")) return inputtingSignupOnBflow();
            if ($("#teamStep").is(":visible")) return inputtingTeamInfo();
            return;
        }
        if (location.href.includes("consult.act?partner=")) {
            return inputting({
                "MEMBER_TYPE": "기존",
                "BUSINESS_NUMBER": "1898700172",
                "CMNM": "테스트회사",
                "USER_NM": "테스트유저",
                "CLPH_NO": "01089451732",
                "EML": "dellose@naver.com",
                "ROUTE": "뉴스레터",
                "POSITION": "팀장",
                "SECTORS": "제조업",
                "HOPE_CNT": "500",
            })
        }
        if (location.pathname.includes("consult")) {
            return inputting({
                "CMNM": "테스트회사",
                "USER_NM": "테스트유저",
                "CLPH_NO": "01089451732",
                "EML": "dellose@naver.com",
                "ROUTE": "언론/보도자료",
                "POSITION": "파트장",
                "SECTORS": "제조업",
                "HOPE_CNT": "1000",
                "CONTACT_CNTN": "테스트",
                "CONTACT_TYPE": "협업툴 도입검토"
            })
        }
        if (location.pathname.includes("estimateapply")) {
            return inputting({
                "CMNM": "테스트회사",
                "USER_NM": "테스트유저",
                "REGISTER_NUMBER": "1898700172",
                "EML": "dellose@naver.com",
                "CLPH_NO": "01089451732",
                "TOTAL_PERSON": "20",
                "CONTACT_CNTN": "테스트 내용"
            })
        }
    }

    function inputtingTeamInfo(type = 'join'){
        const datetime = getDatetime();
        return inputting({
            [`${type}TeamName`]: `company${datetime}`,
            [`${type}TeamUrl`]: `subdom${datetime}`,
            [`${type}TeamSector`]: "IT",
        })
    }

    function inputtingMainOnPayment(){
        inputting({
            "phoneNum": "01012345678",
            "longCardNum": 9999,
            "expiryMonth": "12",
            "expiryYear": "2032",
            "cardPassword": 99,
            "birthdayNum": "1898700172"
        })
    }

    function inputtingSignupOnGuest() {
        Often.toast("success", `비번은 '${password}' 입니다.`);
        const id = getTestId("guest");
        openMail(id);
        saveEmail(`${id}@yopmail.com`);
        return inputting({
            "joinGuestEmail": `${id}@yopmail.com`,
            "joinGuestName": id,
            "joinGuestPassword": password,
            "joinGuestPassword2": password,
        })
    }

    function inputtingSignupOnBflow() {
        Often.toast("success", `비번은 '${password}' 입니다.`);
        const id = getTestId("bflow");
        openMail(id);
        saveEmail(`${id}@yopmail.com`);
        return inputting({
            "joinUserEmail": `${id}@yopmail.com`,
            "joinUserName": id,
            "password": password,
            "password2": password,
        })
    }

    function getTestId(mode) {
        return `test_${mode}_${getDatetime()}`;
    }

    function getDatetime() {
        return Time.currentTime();
    }

    function openMail(id) {
        if (Often.isServerModeByHost("DEV_TEST")) return;
        setTimeout(function () {
            window.open(`https://yopmail.com/en/?login=${id}`, id, "width=600px, height=600px");
        }, 1000);
    }

    function saveEmail(id) {
        LocalUtil.setLocal("RECENTLY_INPUTTING_ID", id);
    }

    function getSavingEmail() {
        return Often.null2Void(LocalUtil.getLocal("RECENTLY_INPUTTING_ID"));
    }

    function inputting(json) {
        for (const k in json) {
            $(`#${k}`).val(json[k]);
        }
    }

})()