var IntercomHelper = function () {
	const APP_ID = 've27vjjk';

	const drawButton = (isUseOption = true) => {
		if (Often.isGlobal()) connectIntercom(isUseOption);
	}

	// IntercomHelper 채팅 생성
	const connectIntercom = (isUseOption = true) => {
		let isHide = false;
		if(isUseOption) {
			isHide = getHideOption();
		}
		window.intercomSettings = {
			app_id: APP_ID,
			api_base: 'https://api-iam.intercom.io',
			alignment: 'right',
			horizontal_padding: 50,
			vertical_padding: 50,
			hide_default_launcher: isHide,
		};

		(intercomLib)();

		window.Intercom('boot', {
			app_id: APP_ID,
		});
	}

	const intercomLib = () => {
		var w = window;
		var ic = w.Intercom;
		if (typeof ic === "function") {
			ic('reattach_activator');
			ic('update', w.intercomSettings);
		} else {
			var d = document;
			var i = function () {
				i.c(arguments);
			};
			i.q = [];
			i.c = function (args) {
				i.q.push(args);
			};
			w.Intercom = i;
			var l = function () {
				var s = d.createElement('script');
				s.type = 'text/javascript';
				s.async = true;
				s.src = 'https://widget.intercom.io/widget/' + APP_ID;
				var x = d.getElementsByTagName('script')[0];
				x.parentNode.insertBefore(s, x);
			};
			if (document.readyState === 'complete') {
				l();
			} else if (w.attachEvent) {
				w.attachEvent('onload', l);
			} else {
				w.addEventListener('load', l, false);
			}
		}
	}

	class IntercomUser {
		constructor(email, user_id, user_hash, payInfo) {
			const globalData = window.globalValue;
			this.app_id = APP_ID;
			this.app_base = 'https://api-iam.intercom.io';
			this.email = email;
			this.user_id = user_id;
			this.user_hash = user_hash;
			this.plan_name = PlanFunc.getPlanInfo().PLAN_ID;
			this.job_title = globalData?.dvsnInfo?.RSPT_NM || '';
			this.hide_default_launcher = getHideOption();
			this.makeUserData(globalData.buyInfo, payInfo);
			this.makeCompanyData(globalData, payInfo);
		}

		makeUserData(buyInfo, payInfo) {
			this.industry = buyInfo?.PATH_CD || '';
			this.trial_days_left = !buyInfo.GRC_DT ? "0" : buyInfo.GRC_DT;
			this.role = buyInfo["MNGR_DSNC"] === "Y" ? "admin" : "user";

			if(!payInfo["SUBSCRIPTIONENDDTTM"]) { //체험기간이고 결제정보가 없는 경우
				this.trial = true;
				this.subscription_start_date_at = dayjs(buyInfo.APLC_DT).unix();
				this.subscription_end_date_at = dayjs(buyInfo.APLC_DT).add('31','day').unix();
			} else {
				this.trial = false;
				this.subscription_start_date_at = dayjs(payInfo["RGSN_DTTM"]).unix();
				this.subscription_end_date_at = dayjs(payInfo["SUBSCRIPTIONENDDTTM"]).unix();
			}
		}

		makeCompanyData(globalData, payInfo) {
			this.company = {
				company_id: globalData.sessionInttId,
				company_name : globalData.sessionInttNm,
				plan_name: PlanFunc.getPlanInfo().PLAN_ID,
				name: globalData.sessionInttNm,
				trial : !payInfo["SUBSCRIPTIONENDDTTM"] ? true : false,
				trial_days_left : !globalData.buyInfo.GRC_DT ? "0" : globalData.buyInfo.GRC_DT,
				company_workspace_url : globalData.inttInfo["TEAM_URL"],
			}
		}
	}

	class UpdatedIntercomUser extends IntercomUser {
		constructor(isIntegrated, cntInfo, ...rest) {
			super(...rest);
			this.video_chat_integrated = isIntegrated;
			this.setCntInfo(cntInfo);
		}

		setCntInfo(cntInfo) {
			this.invitations = cntInfo["inviteCnt"];
			this.projects = cntInfo["PROJECT_CNT"];
			this.tasks = cntInfo["TASK_CNT"];
			this.company.company_projects = cntInfo["COMPANY_PROJECT_CNT"];
			this.company.company_tasks = cntInfo["COMPANY_TASK_CNT"]
		}
	}

	const initIntercomUser = async () => {
		if(Often.isAct("index") || !Often.isGlobal()) return;

		const [hashValue, payInfo] = await Promise.allSettled([
			getHashValue(),
			getPayInfo(),
			typeof Intercom == "undefined" ? connectIntercom() : null,
		])

		Intercom('create', new IntercomUser(
			_USER_EMAIL, _USER_ID, hashValue.value, payInfo.value
		));
	}

	const initIntercom = () => {
		if(!Often.isGlobal() || !Often.isFunc("INTERCOM")) return;
		setTimeout(() => initIntercomUser(), 1000);
	}

	const updateIntercomUser = async () => {
		if(!Often.isGlobal() || !Often.isFunc("INTERCOM")) return;
		const [hashValue, isIntegrated, cntInfo, payInfo] = await Promise.allSettled([
			getHashValue(),
			getVideoChatIntegration(),
			getCntInfo(),
			getPayInfo(),
			typeof Intercom == "undefined" ? connectIntercom() : null,
		])

		Intercom('update', new UpdatedIntercomUser(isIntegrated.value, cntInfo.value, _USER_EMAIL, _USER_ID, hashValue.value, payInfo.value));

		function getInvitationCnt () {
			return new Promise(resolve => {
				Ajax.executeApi("FLOW_JOIN_R001", {
					"USER_ID" : _USER_ID,
					"RGSN_DTTM" : _RGSN_DTTM
				}, function (dat) {
					resolve(dat.JOIN_CNT);
				});
			})
		}

		function getVideoChatIntegration () {
			const integrationList = JSON.parse(LocalUtil.getLocal('ONLY_USER_INTEGRATION'));
			if(integrationList['MsTeams'] === 'Y' || integrationList['Zoom'] === 'Y' ) return true;
			return false;
		}

		async function getCntInfo () {
			return new Promise(resolve => {
				Ajax.executeApi("INTERCOM_DATA_R001", {
					USER_ID: _USER_ID,
					RGSN_DTTM: _RGSN_DTTM,
					USE_INTT_ID : _USE_INTT_ID,
				}, function (dat) {
					resolve({
						"inviteCnt" : getInvitationCnt() ,
						...dat});
				});
			})
		}
	}

	const getPayInfo = () => {
		return new Promise(resolve => {
			Ajax.executeApi("STRIPE_DATA_R001", {
				"USER_ID": _USER_ID,
				"USE_INTT_ID": _USE_INTT_ID,
				"RGSN_DTTM": _RGSN_DTTM
			}, function(dat) {
				resolve(dat);
			});
		})
	}

	const getHashValue = () => {
		return new Promise(resolve => {
			Ajax.executeApi("INTERCOM_HASH_R001", {
				USER_ID: _USER_ID,
			}, function (dat) {
				resolve(dat["ACCESS_TOKEN"]);
			});
		})
	}

	const getHideOption = () => {
		const homepagePath = ['/kr/','/uk/','/vn/','/jp/','/un/'];
		for (let i = 0; i < homepagePath.length ; i++) {
			if(location.pathname.startsWith(homepagePath[i]))  return false;
		}

		return true;
	}

	const updateAttribute = (apiKey, input) => {
		const message = getTriggerMessage(apiKey, input);
		if(!message) return;
		if(!Often.isGlobal() || !Often.isFunc("INTERCOM")) return;
		setTimeout(() => updateIntercomUser(), 2000);

		function getTriggerMessage (apiKey, input) {
			const apiMessage = {
				COLABO2_C102: "Project Created",
				COLABO2_SENDIENCE_C001: "Teammate invited",
				FLOW_UPLOAD_C001: "File Uploaded",
				ACT_S3_PRESIGNED_URL_UPLOAD_SUCCESS: "File Uploaded",
				ACT_GANTT_FILTER_LIST: "Gantt chart used",
				COLABO2_CHAT_MSG_C001: "Chat used",
				COLABO_ZOOM_TOKEN_R001: "Video chat used",
				COLABO2_TASK_U001 : "Task updated",
				FLOW_SUBTASK_C001 : "Task Created",
				COLABO_D101 : "Project Deleted", //프로젝트 수 갱신을 위한 trigger 조건 추가
			}
			if (apiMessage.hasOwnProperty(apiKey)) return apiMessage[apiKey];

			if("COLABO2_COMMT_C101" === apiKey && isTask(input)) {
				return "Task Created";
			}
		}
		function isTask (input) {
			return !!input.TASK_REC ? input.TASK_REC.length === 1 : false;
		}
	}


	return {
		connectIntercom : connectIntercom,
		drawButton: drawButton,
		initIntercomUser: initIntercom,
		updateAttribute : updateAttribute
	}

}()
