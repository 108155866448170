var EditableUtil = (function () {

    return {
        isMentionOrHash: isMentionOrHash,
        isParentsFirstDivContentEditable: isParentsFirstDivContentEditable,
        isParentsContentEditable: isParentsContentEditable,
        isUpperTagContentEditable : isUpperTagContentEditable,
        selectAllTextNode: selectAllTextNode,

        // Create Box
        hasCreateBox: hasCreateBox,
        changeFocusHiddenComponent: changeFocusHiddenComponent,

        // Delete Node
        deleteFocusNode: deleteFocusNode,
        removeHashAndMentionElement: removeHashAndMentionElement, // ie
        changeRemoveNode2FocusSibling: changeRemoveNode2FocusSibling,

        // Change Range
        focusOutHashAndMention: focusOutHashAndMention,

    }

    function stopEditableEvent(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * @description 해시태그 혹은 멘션태그가 텍스트노드와 있을 때 영역을 잘 잡지 못해서 잡아줌
     */
    function removeHashAndMentionElement(e) {
        var selection = window.getSelection();
        var focusNode = selection.focusNode;
        if (!focusNode) return;
        var isDelete = KeyCheck.isKey(e, "DELETE");
        var isBackSpace = KeyCheck.isKey(e, "BACK");
        var isTextNode = focusNode.nodeName === "#text";

        if (isLastOffset(e) && isTextNode) {
            const focusPrevious = Often.null2Void(focusNode.previousSibling);
            const focusNext = Often.null2Void(focusNode.nextSibling);
            if (isBackSpace) {
                if (focusPrevious.length === 0 && Often.null2Void(focusPrevious.childNodes).length === 0) return false;
                focusNode = focusPrevious.childNodes[0];
            } else if (isDelete) {
                if (focusNext.length === 0 && Often.null2Void(focusNext.childNodes).length === 0) return false;
                focusNode = focusNext.childNodes[0];
            }
        }


        if (isMentionOrHash(focusNode)) {
            focusNode = (isTextNode ? focusNode.parentNode : focusNode);
            $(focusNode).remove();
            return stopEditableEvent(e);
        }
    }

    function isMentionOrHash(focusNode) {
        if (!focusNode || focusNode === "") return false;
        return (Caret.isMentionOrHashTag(focusNode) || Caret.isParentMentionOrHashNode(focusNode));
    }


    /**
     * @description IE에서 텍스트노드의 포커스가 ContentEditable=false 태그 안으로 들어가는 이슈를 막기 위함
     */
    function focusOutHashAndMention(e) {
        var selection = window.getSelection();
        var focusNode = selection.focusNode;
        if (!focusNode) return;

        var isStartOffset = selection.focusOffset === 0;
        if (!isMentionOrHash(focusNode)) return;

        var parentNode = focusNode.parentNode;
        var nbsp = document.createTextNode("\u00A0");
        isStartOffset ? $(parentNode).before(nbsp) : $(parentNode).after(nbsp);

        var focusSibling = isStartOffset ? parentNode.previousSibling : parentNode.nextSibling;
        selection.removeAllRanges();

        var range = document.createRange(); // 포커스 위치 이동
        range.selectNodeContents(focusSibling);
        selection.addRange(range);
    }


    /**
     * @description ContentEditable에서 빈 문자열을 Delete로 지우면 다음 열에 개행이 추가되기 때문에 개행 삭제 처리
     */
    function deleteFocusNode(e) {
        if (Often.isBrowser("ie")) return;
        var selection = window.getSelection();
        var focusNode = selection.focusNode;
        var isAnotherNode = focusNode !== selection.anchorNode;
        if (isAnotherNode) return;
        var isFocusContentArea = $(focusNode).hasClass("js-upload-area");
        if (isFocusContentArea) return e.preventDefault();

        var isDiv = focusNode && focusNode.nodeName === "DIV";
        if (isDiv) {
            changeRemoveNode2FocusSibling(e, selection)
        }
    }

    /**
     *
     * @param $currentArea 텍스트 박스 영역
     * @param exceptClass  선택 제한 클래스
     * @description ( ctrl + a ) 기능 사용시 제한 클래스 element 전 까지 선택
     */

    function selectAllTextNode(e, $currentArea, exceptClass) {
        var selection = window.getSelection();
        var $focusNode = $(selection.focusNode);
        var $parentNode = ($focusNode.context && $focusNode.context.nodeType === 3) ? $focusNode.parents("div") : $focusNode;

        var currentArea = $currentArea[0];
        var currentChilndNodes = currentArea.childNodes;
        var focusIndex = -1;
        var exceptArray = [];

        $.each(currentChilndNodes, function (e, node) {
            if (node === $parentNode[0]) focusIndex = e;
            if ($(node).hasClass(exceptClass)) {
                exceptArray.push(e);
                if (focusIndex > -1) return false; // FocusNode의 위치 다음 ExceptNode면 반복문 종료
            }
        });

        // ExceptNode가 없을 때는 Document.execCommand() 실행
        if (exceptArray.length === 0) return;
        stopEditableEvent(e);
        var isFinalFocusNode = exceptArray[exceptArray.length - 1] < focusIndex;
        var startOffset;
        var endOffset;
        if (isFinalFocusNode) { // 시작점이 ExceptNode이고, 끝까지 블록 지정
            startOffset = exceptArray[exceptArray.length - 1] + 1;
            endOffset = currentChilndNodes.length;
        } else {
            if (exceptArray.length > 1) { // 시작점이 ExceptNode이고, 다음 ExceptNode까지 블록 지정
                startOffset = exceptArray[exceptArray.length - 2] + 1;
                endOffset = exceptArray[exceptArray.length - 1];
            } else { // currentArea 처음부터 첫번째 ExceptNode까지 블록 지정
                startOffset = 0;
                endOffset = exceptArray[0];
            }
        }

        var isIe = Often.isBrowser("ie");
        var startNode = (isIe ? currentArea : currentChilndNodes[startOffset]);
        var endNode = (isIe ? currentArea : currentChilndNodes[endOffset - 1]);
        startOffset = (isIe ? startOffset : 0);
        endOffset = (isIe ? endOffset : 1);

        if (!isIe && startNode === endNode) {
            selection.selectAllChildren(startNode);
        } else {
            var range = document.createRange();
            range.setStart(startNode, startOffset);
            range.setEnd(endNode, endOffset);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    function changeRemoveNode2FocusSibling(e, selection) {
        var focusNode = selection.focusNode;
        var focusSibling = getDeleteSiblingNode(e, focusNode);
        if (!focusSibling || focusSibling.length === 0) return;

        var isOnlyBrNode = (focusNode.childNodes.length === 1 && focusNode.childNodes[0].nodeName === "BR");
        if (isOnlyBrNode) {
            stopEditableEvent(e);

            $(focusNode).remove();
            selection.removeAllRanges();

            var range = document.createRange();
            range.selectNodeContents(focusSibling);
            selection.addRange(range);

            var isBackSpace = KeyCheck.isKey(e, "BACK");
            var isDelete = KeyCheck.isKey(e, "DELETE");
            isBackSpace && selection.collapseToEnd();
            isDelete && selection.collapseToStart();
        }
    }

    /**
     * @returns focusNode | ""
     */
    function getDeleteSiblingNode(e, focusNode) {
        if (!focusNode || focusNode.length === 0) return "";
        var isBackSpace = KeyCheck.isKey(e, "BACK");
        var isDelete = KeyCheck.isKey(e, "DELETE");
        var focusSibling = isBackSpace ? focusNode.previousSibling : isDelete ? focusNode.nextSibling : "";
        return focusSibling;
    }

    function hasCreateBox(e) {
        var focusNode = window.getSelection().focusNode;
        var focusSibling = getDeleteSiblingNode(e, focusNode);
        var isCreateBox = $(focusSibling).hasClass("create-box");
        if (isCreateBox) {
            if (!isLastOffset(e)) return false;

            var isEmptyText = Often.null2Void(focusNode.textContent).length === 0;
            if (hasFocusSiblingCreateBox(focusNode) && isEmptyText) return true;
        }
    }

    function isLastOffset(e) {
        var isBackSpace = KeyCheck.isKey(e, "BACK");
        var isDelete = KeyCheck.isKey(e, "DELETE");

        var selection = window.getSelection();
        var focusNode = selection.focusNode;
        var focusOffset = selection.focusOffset;

        var focusTextLength = Often.null2Void(focusNode.textContent).length;
        if (isBackSpace && focusTextLength === 0) return true;
        if (isDelete && (focusTextLength === 0 || focusTextLength === focusOffset)) return true;
        return false;
    }

    /**
     * @description FocusNode 위 아래로 CreateBox가 있는지 체크
     */
    function hasFocusSiblingCreateBox(focusNode) {
        if (!focusNode) return;

        var nodeName = Often.null2Void(focusNode.nodeName, "");
        var isTextNode = (nodeName === "#text");
        var $targetNode = (isTextNode ? $(focusNode.parentNode) : $(focusNode));

        var hasCreateBox = hasSiblingCreateBox($targetNode) || hasAfterCreateBox($targetNode) || hasBeforeCreateBox($targetNode);
        return hasCreateBox;
    }

    function changeFocusHiddenComponent(e) {
        var $focusNode = $(window.getSelection().focusNode);
        $focusNode.attr({
            "class": "js-hidden-component hidden-component",
            "contenteditable": "false"
        }).html("&nbsp;"); // component 사이 공백 지울때 사용
        return stopEditableEvent(e);
    }

    function hasSiblingCreateBox($node) {
        var isBeforeCreateBoxClass = $node.prev().hasClass("create-box");
        var isAfterCreateBoxClass = $node.next().hasClass("create-box");
        return (isBeforeCreateBoxClass && isAfterCreateBoxClass);
    }

    function hasBeforeCreateBox($node) {
        var isBeforeCreateBoxClass = $node.prev().hasClass("create-box")
        var isAfterNoComponent = ($node.next().length === 0);
        return (isBeforeCreateBoxClass && isAfterNoComponent);
    }

    function hasAfterCreateBox($node) {
        var isBeforeNoComponent = ($node.prev().length === 0);
        var isAfterCreateBoxClass = $node.next().hasClass("create-box")
        return (isBeforeNoComponent && isAfterCreateBoxClass);
    }

    function isParentsFirstDivContentEditable(focusNode) {
        return $($(focusNode).parents("div")[0]).attr("contenteditable") === 'true';
    }
    // 내가 지금 붙여넣고 있는 곳의 바로위 태그가 contenttalble
    function isUpperTagContentEditable(focusNode) {
        return $(focusNode).parent().attr("contenteditable") === 'true';
    }
    function isParentsContentEditable(focusNode) {
        return $(focusNode).closest("div[contenteditable=true]").length > 0;
    }

})();