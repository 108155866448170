var PhoneNumKeyEvent = (function () {

    return {
        validNumOnKeypress: validNumOnKeypress,
        validNumOnKeyUp: validNumOnKeyUp,
        validOnInput : validOnInput,
        validNonSpecialCharOnKeypress : validNonSpecialCharOnKeypress,
    }

    function validNonSpecialCharOnKeypress(event) {
        // + 랑 문자열 공백만 허용
        const regex = new RegExp(/^[\w+\s]+$/u);
        if (!regex.test(String.fromCharCode(event.charCode))) {
            event.preventDefault();
            return;
        }
    }
    function validNumOnKeypress(event) {
        const key = event.which || event.keyCode; //use event.which if it's truthy, and default to keyCode otherwise
        const controlKeys = [8, 9, 13, 45, 46, 43, 187, 17 ,16, 39]; // Allow: backspace, delete, tab, enter, -, +
        const isControlKey = event.ctrlKey || controlKeys.join(",").match(new RegExp(key)); // Ctrl+ anything or one of the conttrolKeys is valid
        if (isControlKey) {return;}

        if (!(48 <= key && key <= 57)) { // stop current key press if it's not a number
            event.preventDefault();
            return;
        }
    }

    function validNumOnKeyUp (e) {
        const testValue = this.value || e.target.value;
        const regex = new RegExp(/^[\+?(\d*|\-(?!\-))*]{1,20}$/g);
        if (!regex.test(testValue)) {
            !!this.value ? this.value = this.value.replace(/[^+\-0-9]/g, '') :
            $(e.target).val(testValue.replace(/[^+\-0-9]/g, ''));
        }
    }

    function validOnInput($eTarget) {
        const $numberInput = $eTarget.findUp(".js-phone-input");
        if ($numberInput.length === 0) return false;
        $numberInput.val($numberInput.val().replace(/[^+\-0-9]/g, ""));
        return true;
    }
})();
