var Ajax = (function () {

    var returnJson = {};
    const MainSocket = requireModule('MainSocket', {on: ['main']});
    const isReal = Often.isServerModeByHost("REAL") || Often.isServerModeByHost("REAL_TEST");
    const tourId = isReal ? "10project_temp@flow.team" : "10project_temp@yopmail.com";

    return {
        initData: initData,
        getData: getData,
        getOutData: getOutData,
        executeApi: executeApi,
	    executeApiPromise: executeApiPromise,

        OPTION: {
            ALLOW_EXECUTE: 0, // 중복 실행 허용 && 중복 콜백 허용
            PREVENT_EXECUTE: 1, // 중복 실행 방지( = 첫번째 실행이 종료될때까지 방지)
            PREVENT_CALLBACK: 2,  // 중복 콜백 무시 ( = 마지막 콜백이 실행 )
        },
    };

    function initData() {
        returnJson = {};
    }

    function getData(apiKey) {
        if (apiKey === undefined) {
            return returnJson;
        } else {
            return returnJson[apiKey];
        }
    }

    function getOutData(apiKey, dataKey) {
        if (returnJson && returnJson[apiKey] && returnJson[apiKey].response && returnJson[apiKey].response[dataKey]) {
            return returnJson[apiKey].response[dataKey];
        } else {
            return "";
        }
    }

    async function executeApiPromise(apiKey, inputJson) {
        return new Promise((resolve, reject) => {
            executeApi(apiKey, inputJson, (result) => {
                !!result ? resolve(result) : reject(new Error("API Error"))
            }, (result) => {
                !!result ? resolve(result) : reject(new Error("API Error"))
            })
        })
    }

    async function executeApi(apiKey, inputJson, successCallback, errorCallback, isMaster = false, finalCallback) {
        const __USER_ID = window._USER_ID || '';
        const __RGSN_DTTM = window._RGSN_DTTM || '';

        inputJson = inputJson || {}

        const nowMilliSecond = Date.now();
        let packetOption;
        const asyncStatus = (inputJson.useSyncAjax === undefined ? true : Boolean(Often.null2Void(inputJson.useSyncAjax, true)))
        if (inputJson.packetOption === undefined) {
            packetOption = Ajax.OPTION.ALLOW_EXECUTE;
        } else {
            packetOption = inputJson.packetOption;
        }

        // 중복 실행 방지
        if (packetOption === Ajax.OPTION.PREVENT_EXECUTE &&
            isPreventExecuteApi(returnJson[apiKey], inputJson)) {
            return;
        }

        if (!returnJson[apiKey]) {
            returnJson[apiKey] = {};
        }
        returnJson[apiKey].request = inputJson;
        inputJson.RGSN_DTTM = __RGSN_DTTM; // 들어온 INPUT에 RGSN_DTTM을 모두 보정
        // if (inputJson.RGSN_DTTM.length === 14) inputJson.RGSN_DTTM = __RGSN_DTTM; // 14자리로된 RGSN_DTTM만 보정

        const ajaxJson = {
            "_JSON_": encodeURIComponent(JSON.stringify($.extend({}, {
                USER_ID: __USER_ID,
                RGSN_DTTM: __RGSN_DTTM,
            }, Time.tzrApi(inputJson))))
        }

        var queryString = "";
        if ("" !== Often.null2Void(inputJson.MODE)) {
            queryString = "&mode=" + inputJson.MODE
        } else if ("" !== Often.null2Void(inputJson.GUBUN)) {
            queryString = "&mode=" + inputJson.GUBUN
        }
        if ("" !== Often.null2Void(inputJson.PG_NO) && Number(inputJson.PG_NO) > 1) {
            queryString += "&page=" + inputJson.PG_NO
        }
        if ("" !== queryString) {
            queryString = "?" + queryString.slice(1, queryString.length);
        }

        if (isMaster && Often.isGlobal()) await Mutil.delay(800)

        return $.ajax({
            type: "POST",
            url: "/" + apiKey + ".jct" + queryString,
            data: ajaxJson,
            cache: true,
            async: asyncStatus,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Client-Connection-Region', Often.getCookie('Client-Connection-Region'));
                returnJson[apiKey].isExecute = true;
                returnJson[apiKey].finalResMilliSecond = nowMilliSecond;
            },
            error: function (e) {
                console.error(e);
                if(window?.ElectronProcessApi){
                    const roomSrno = window._ROOM_SRNO
                    const url = location.href
                    ElectronApi.Comm.setAjaxErrorToElectron({e, apiKey, roomSrno, url})
                }
                returnJson[apiKey].isExecute = false;
            },
            success: async function (res) {
                Mutil.isFunc('API_DELAY_500') && await Mutil.delay(500);
                returnJson[apiKey].isExecute = false;

                if ((__USER_ID === tourId) && !Often.isFunc("PROJECT_TOUR")) {
                    location.replace('/main.act');
                }

                if (packetOption === Ajax.OPTION.PREVENT_CALLBACK && !isFinalExecuteBySameApi()) {
                    Often.clog("중복콜백무시", apiKey);
                    return;
                }

                if (!res || !res['COMMON_HEAD']) {
                    (typeof errorCallback === 'function') && errorCallback(res);
                    return;
                }

                var commonHead = res['COMMON_HEAD'];
                var isError = commonHead['ERROR'];
                var errorCode = commonHead['CODE'];
                var errorMsg = commonHead['MESSAGE'];

                if (isError) {
                    var isNoSession = '' === __RGSN_DTTM && (errorCode === '400' || errorCode === '9101');
                    if (isNoSession) {
                        var url = TeamsHelper.isTeams()? "/teams/" : '/signin.act'
                        location.replace(url);
                        return;
                    }

                    AjaxError.errorHandle(res, errorCode, errorMsg, errorCallback, apiKey, inputJson);

                    if ("" !== errorCode) {
                        (typeof finalCallback === 'function') && finalCallback(res);
                        return;
                    }
                }
                IntercomHelper.updateAttribute(apiKey, inputJson);
                returnJson[apiKey].response = Time.tzApi(res);
                (typeof successCallback === 'function') && successCallback(Time.tzApi(res));
            }
        });

        /**
         * @description 실시간 갱신용 로직.
         * 실시간 갱신을 위해 업데이트가 되었음을 알려줘야하는 API 가 호출되는 경우엔 바로 소켓을 보내준다.
         * 링크참고: https://docs.google.com/spreadsheets/d/1ExSL2EvEZ52ahiF-ZuBnjB7AcLcNLgWlgCyCu23OOoQ/edit#gid=1287712533
         */
        function isSocketTarget(apiKey) {
            return [
                'COLABO2_COMMT_C101',
                'COLABO2_COMMT_U101',
                'COLABO2_COMMT_D101',
                'FLOW_SUBTASK_C001',
                'FLOW_SUBTASK_D001',
                'COLABO2_TASK_U001',
                'FLOW_TASK_WORKER_U002',
                'FLOW_VOTE_C001',
                'FLOW_SCHD_ATD_U001',
            ].indexOf(apiKey) > -1
        }

        function isFinalExecuteBySameApi() {
            var lastRequestInputJson = $.extend({}, returnJson[apiKey].request);
            var currentRequestInputJson = $.extend({}, inputJson);
            skipData(["PG_PER_CNT", "FIL_STTS", "FIL_PRIORITY", "SRCH_WORD", "SEARCH_RECORD"]);
            return !((JSON.stringify(lastRequestInputJson) === JSON.stringify(currentRequestInputJson)) &&
                (returnJson[apiKey].finalResMilliSecond !== nowMilliSecond));

            /**
             * Note. 중복콜백 체크시 해당 keyArray 는 예외하고 진행한다.
             * Ex1. 전체업무 상태 필터를 빠른 속도로 OnOff 할때 INPUT 에서 FIL_STTS, FIL_PRIORITY 빼고 비교해야함
             * Ex2. 담당자 검색을 빠른 속도로 OnOff 할때 SRCH_WORD 빼고 비교해야함
             */
            function skipData(keyArray) {
                keyArray.forEach(function (key) {
                    lastRequestInputJson[key] = "";
                    currentRequestInputJson[key] = "";
                })
            }
        }
    }

    function isExecuteRequestApi(requestApi = {}) {
        return requestApi.isExecute;
    }

    function getRequestApiMode (requestApi = {}) {
        const {request = {}} = requestApi;
        const {MODE = '', GUBUN = ''} = request;
        return MODE || GUBUN;
    }

    function getInputMode (inputJson = {}) {
        const {MODE = '', GUBUN = ''} = inputJson;
        return MODE || GUBUN;
    }

    function isPreventExecuteApi (requestApi = {}, inputJson = {}) {
        const {
            isCompareQueryString = false,
        } = inputJson;

        const isExecute = isExecuteRequestApi(requestApi);

        if (isCompareQueryString) {
            const inputMode = getInputMode(inputJson);
            const requestMode = getRequestApiMode(requestApi)
            return isExecute && (inputMode === requestMode);
        }
        return isExecute;
    }
})();
